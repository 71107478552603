import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "styles/Global.css";
import { unityContext } from "components/UnityComponent/UnityComponent";

const location = window.location.href;
console.log(location);
var context = unityContext;
console.log(context);

const productionLinks = [
  "https://run.atlas.space/",
  "https://meta.atlas.space/",
  "https://enter.atlas.space/",
  "https://aveonglobal.atlas.space/",
  "https://bilgi.atlas.space/",
  "https://bim.atlas.space/",
  "https://bitbasel.atlas.space/",
  "https://blocksforhope.atlas.space/",
  "https://bms.atlas.space/",
  "https://botspot.atlas.space/",
  "https://bulutistan.atlas.space/",
  "https://denizbank.atlas.space/",
  "https://gureli.atlas.space/",
  "https://ihc.atlas.space/",
  "https://investroom.atlas.space/",
  "https://medibooth.atlas.space/",
  "https://metacampus.atlas.space/",
  "https://metaverseunion.atlas.space/",
  "https://nike.atlas.space/",
  "https://pab.atlas.space/",
  "https://powergroup.atlas.space/",
  "https://startgate.atlas.space/"
];

if (productionLinks.includes(window.location.href)) {
  window.console.log =
    window.console.debug =
    window.console.info =
    window.console.error =
      function () {
        return false;
      };
}

// if (
//   location === "https://run.atlas.space/" ||
//   location === "https://startgate.atlas.space/"
// ) {
//   window.console.log =
//     window.console.debug =
//     window.console.info =
//     window.console.error =
//       function () {
//         return false;
//       };
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <App />
  </Router>
);
